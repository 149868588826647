import React from "react"
import { Flex, Box } from "rebass"
import CardStyled from "./CardStyled"
import SpringEnamel1 from "./images/SpringEnamel/SpringEnamel1"
import SpringEnamel2 from "./images/SpringEnamel/SpringEnamel2"
import SpringEnamel3 from "./images/SpringEnamel/SpringEnamel3"
import SpringEnamel4 from "./images/SpringEnamel/SpringEnamel4"
import SpringEnamel5 from "./images/SpringEnamel/SpringEnamel5"
import SpringEnamel6 from "./images/SpringEnamel/SpringEnamel6"
import SpringEnamel7 from "./images/SpringEnamel/SpringEnamel7"
import SpringEnamel8 from "./images/SpringEnamel/SpringEnamel8"
import SpringEnamel9 from "./images/SpringEnamel/SpringEnamel9"

const SpringEnamel = () => (
  <Flex flexWrap="wrap" px={[2, 3, 6]} pt={[3, 4, 6]}>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <SpringEnamel1 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <SpringEnamel2 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <SpringEnamel3 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <SpringEnamel4 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <SpringEnamel5 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <SpringEnamel6 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <SpringEnamel7 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <SpringEnamel8 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <SpringEnamel9 />
      </CardStyled>
    </Box>
  </Flex>
)
export default SpringEnamel
