import * as React from "react"

import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import WrapperContainers from "../components/WrapperContainer"
import { Box, Flex } from "rebass"
import CenterH2 from "../components/Typography/H2/CenterH2"
import SpanGreen from "../components/Typography/Span/SpanGreen"
import TabStyled from "../containers/Gallery/TabStyled"
import CenterP from "../components/Typography/P/CenterP"
import StyledGalleryHeroBg from "../containers/Gallery/Hero/GalleryHero"

const Gallery = () => (
  <Layout>
    <Seo title="Gallery Gold and silver jewelry" />

    <StyledGalleryHeroBg />
    <Section>
      <Flex flexWrap="wrap">
        <Box width={[1]}>
          <CenterH2>
            Our <SpanGreen>Gallery</SpanGreen>
          </CenterH2>
          <CenterP>
            View an assortment of our factory's latest jewelry collections
          </CenterP>
        </Box>
      </Flex>
      <TabStyled />
    </Section>
  </Layout>
)
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`
export default Gallery
