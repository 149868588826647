import React from "react"
import { Flex, Box } from "rebass"
import CardStyled from "./CardStyled"
import Color1 from "./images/ColorGemstones/Color1"
import Color2 from "./images/ColorGemstones/Color2"
import Color3 from "./images/ColorGemstones/Color3"
import Color4 from "./images/ColorGemstones/Color4"
import Color5 from "./images/ColorGemstones/Color5"
import Color6 from "./images/ColorGemstones/Color6"
import Color7 from "./images/ColorGemstones/Color7"
import Color8 from "./images/ColorGemstones/Color8"
import Color9 from "./images/ColorGemstones/Color9"

const ColorGemsStones = () => (
  <Flex flexWrap="wrap" px={[2, 3, 6]} pt={[3, 4, 6]}>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Color1 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Color2 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Color3 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Color4 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Color5 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Color6 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Color7 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Color8 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Color9 />
      </CardStyled>
    </Box>
  </Flex>
)
export default ColorGemsStones
