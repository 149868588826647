import React from "react"
import { Flex, Box } from "rebass"
import CardStyled from "./CardStyled"
import Pearl1 from "./images/Pearl/Pearl1"
import Pearl2 from "./images/Pearl/Pearl2"
import Pearl3 from "./images/Pearl/Pearl3"
import Pearl4 from "./images/Pearl/Pearl4"
import Pearl5 from "./images/Pearl/Pearl5"
import Pearl6 from "./images/Pearl/Pearl6"
import Pearl7 from "./images/Pearl/Pearl7"
import Pearl8 from "./images/Pearl/Pearl8"
import Pearl9 from "./images/Pearl/Pearl9"

const Pearl = () => (
  <Flex flexWrap="wrap" px={[2, 3, 6]} pt={[3, 4, 6]}>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Pearl1 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Pearl2 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Pearl3 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Pearl4 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Pearl5 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Pearl6 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Pearl7 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Pearl8 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Pearl9 />
      </CardStyled>
    </Box>
  </Flex>
)
export default Pearl
