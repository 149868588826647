import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import H1 from "../../../components/Typography/H1/H1"
const GalleryBg = ({ className }) => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "hero-gallery.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopImage: file(relativePath: { eq: "hero-gallery.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 491px)`,
    },
  ]

  return (
    <BackgroundImage
      Tag={`section`}
      id={`media-test`}
      className={className}
      fluid={sources}
    >
      <StyledInnerWrapper>
        <OverlayLogo src="https://res.cloudinary.com/denq2jckc/image/upload/v1632639145/Home/logo-fixed_gzz0cl.svg" />
        <WhiteH1>Our Gallery</WhiteH1>
      </StyledInnerWrapper>
    </BackgroundImage>
  )
}

const StyledInnerWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: left;
  padding: 15vh 0;
`

const StyledGalleryBg = styled(GalleryBg)`
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 70vh;
  top: -20%;
  @media (max-width: 768px) {
    min-height: 60vh;
    padding-top: 50px;
  }
`
const OverlayLogo = styled.img`
  height: auto;
  position: absolute;
  width: 20vw;
  top: 24vh;
  right: 9vw;
  opacity: 0.45;
  @media (max-width: 768px) {
    display: block;
    margin: auto;
    top: 8vh;
    left: 6vw;
    opacity: 0.75;
    width: 25vw;
  }
`
const WhiteH1 = styled(H1)`
  opacity: 1;
  color: ${({ theme }) => theme.headers};
  padding-top: 9vh;
  padding-right: 16vw;
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: right;
  @media (max-width: 768px) {
    position: absolute;
    top: 38vh;
    background-color: ${({ theme }) => theme.whiteRgba4};
    min-width: 90vw;
    text-align: center;
    font-size: calc(2.3rem + 2vw);
    margin: 0 4vw;
    padding: 1.5vh 1vw;
  }
`

export default StyledGalleryBg
