import { Card } from "rebass"
import styled from "styled-components"

const CardStyled = styled(Card)`
  font-size: calc(0.8rem + 2vw);
  color: ${({ theme }) => theme.h1};
  font-family: "OFL Sorts Mill Goudy";
  text-transform: capitalize;
`

export default CardStyled
