import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Pearl8 = () => (
  <Container>
    <StaticImage
      src="../../../../images/Gallery/Pearl/az5261 (900x900px).jpg"
      alt="Yellow Gold Freshwater Pearl-Crinkled jewelry manufacturers"
      placeholder="blurred"
      layout="fullWidth"
    />
    <Overlay />
  </Container>
)
export default Pearl8
const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;

  :hover {
    background: rgb(41, 217, 213, 0.35) 0% 0% no-repeat padding-box;
    width: 98.6%;
    height: 99%;
    border: 4px solid rgb(41, 217, 213, 1);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
  }
`
const Container = styled.div`
  position: relative;
`
