/**
 * TabStyled component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"

import styled from "styled-components"
import ColorGemsStones from "./ColorGemsStones"
import GoldWithDiamonds from "./GoldWithDiamonds"
import Kids from "./Kids"
import Pearl from "./Pearl"
import Rainbow from "./Rainbow"
import SpringEnamel from "./SpringEnamel"

const TabStyled = ({ children }) => {
  const [active, setActive] = useState(0)
  const handleClick = e => {
    const index = parseInt(e.target.id, 0)
    if (index !== active) {
      setActive(index)
    }
  }
  return (
    <div>
      <Tabs>
        <Tab onClick={handleClick} active={active === 0} id={0}>
          Rainbow
        </Tab>

        <Tab onClick={handleClick} active={active === 1} id={1}>
          Gold With Diamonds
        </Tab>
        <Tab onClick={handleClick} active={active === 2} id={2}>
          Kids
        </Tab>
        <Tab onClick={handleClick} active={active === 3} id={3}>
          Color Gemstones
        </Tab>
        <Tab onClick={handleClick} active={active === 4} id={4}>
          Pearl
        </Tab>
        <Tab onClick={handleClick} active={active === 5} id={5}>
          Spring Enamel
        </Tab>
      </Tabs>
      <>
        <Content active={active === 0}>
          <Rainbow />
        </Content>
        <Content active={active === 1}>
          <GoldWithDiamonds />
        </Content>
        <Content active={active === 2}>
          <Kids />
        </Content>
        <Content active={active === 3}>
          <ColorGemsStones />
        </Content>
        <Content active={active === 4}>
          <Pearl />
        </Content>
        <Content active={active === 5}>
          <SpringEnamel />
        </Content>
      </>
    </div>
  )
}

export default TabStyled
export const Tabs = styled.div`
  overflow: hidden;
  text-align: center;
`

export const Tab = styled.button`
  border: none;
  outline: none;
  cursor: pointer;

  margin-right: 0.8vw;
  border-bottom: ${props => (props.active ? "4px solid #29D9D5" : "none")};
  color: ${props => (props.active ? "#29D9D5" : "#6D6D6B")};
  transition: background-color 0.9s ease-in-out;
  background-color: transparent;
  font-size: calc(0.6rem + 0.6vw);

  font-family: "Celias";
  text-transform: capitalize;
  font-weight: normal;
`

export const Content = styled.div`
  ${props => (props.active ? "" : "display:none")}
`
