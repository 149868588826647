import React from "react"
import { Flex, Box } from "rebass"
import CardStyled from "./CardStyled"
import Rainbow1 from "./images/Rainbow/Rainbow1"
import Rainbow2 from "./images/Rainbow/Rainbow2"
import Rainbow3 from "./images/Rainbow/Rainbow3"
import Rainbow4 from "./images/Rainbow/Rainbow4"
import Rainbow5 from "./images/Rainbow/Rainbow5"
import Rainbow6 from "./images/Rainbow/Rainbow6"
import Rainbow7 from "./images/Rainbow/Rainbow7"
import Rainbow8 from "./images/Rainbow/Rainbow8"
import Rainbow9 from "./images/Rainbow/Rainbow9"

const Rainbow = () => (
  <Flex flexWrap="wrap" px={[2, 3, 6]} pt={[3, 4, 6]}>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Rainbow1 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Rainbow2 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Rainbow3 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Rainbow4 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Rainbow5 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Rainbow6 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Rainbow7 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Rainbow8 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Rainbow9 />
      </CardStyled>
    </Box>
  </Flex>
)
export default Rainbow
