import React from "react"
import { Flex, Box } from "rebass"
import CardStyled from "./CardStyled"
import Kids1 from "./images/kids/Kids1"
import Kids2 from "./images/kids/kids2"
import Kids3 from "./images/kids/kids3"
import Kids4 from "./images/kids/kids4"
import Kids5 from "./images/kids/kids5"
import Kids6 from "./images/kids/kids6"
import Kids7 from "./images/kids/kids7"
import Kids8 from "./images/kids/kids8"
import Kids9 from "./images/kids/Kids9"

const Kids = () => (
  <Flex flexWrap="wrap" px={[2, 3, 6]} pt={[3, 4, 6]}>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Kids1 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Kids2 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Kids3 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Kids4 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Kids5 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Kids6 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Kids7 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Kids8 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <Kids9 />
      </CardStyled>
    </Box>
  </Flex>
)
export default Kids
