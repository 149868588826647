import React from "react"
import { Flex, Box } from "rebass"
import CardStyled from "./CardStyled"
import GoldWdiamond1 from "./images/GoldWithDiamond/GoldWdiamond1"
import GoldWdiamond2 from "./images/GoldWithDiamond/GoldWdiamond2"
import GoldWdiamond3 from "./images/GoldWithDiamond/GoldWdiamond3"
import GoldWdiamond4 from "./images/GoldWithDiamond/GoldWdiamond4"
import GoldWdiamond5 from "./images/GoldWithDiamond/GoldWdiamond5"
import GoldWdiamond6 from "./images/GoldWithDiamond/GoldWdiamond6"
import GoldWdiamond7 from "./images/GoldWithDiamond/GoldWdiamond7"
import GoldWdiamond8 from "./images/GoldWithDiamond/GoldWdiamond8"
import GoldWdiamond9 from "./images/GoldWithDiamond/GoldWdiamond9"

const GoldWithDiamonds = () => (
  <Flex flexWrap="wrap" px={[2, 3, 6]} pt={[3, 4, 6]}>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <GoldWdiamond1 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <GoldWdiamond2 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <GoldWdiamond3 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <GoldWdiamond4 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <GoldWdiamond5 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <GoldWdiamond6 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <GoldWdiamond7 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <GoldWdiamond8 />
      </CardStyled>
    </Box>
    <Box width={[1, 1, 1 / 3]} p={[3, 3, 3]}>
      <CardStyled>
        <GoldWdiamond9 />
      </CardStyled>
    </Box>
  </Flex>
)
export default GoldWithDiamonds
